 

<div class="page main-signin-wrapper">
  
    <ng-container *ngIf="login">
        <div class="row signpages overflow-hidden">
            <div class="col-sm-12 col-lg-5 d-none d-lg-flex details px-4">
              <div class="position-relative m-auto">
                <img class="" src="../../assets/img/brand/logoFeedbakWhite.svg" alt="">
                <span class="d-block text-white mt-3 text-center op-8">¡Bienvenido!</span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-7 login_form ">
                <form [formGroup]="loginForm">

                    <div class="card-body mt-2 mb-2">
                        <div class="row">
                            <div class="col-12"> 
                              <ng-container *ngIf="invalidCredentials">
                                <ngb-alert type="danger" [dismissible]="true" (close)="closed=true">
                                  <span class="alert-inner--text">Número de empleado o nip incorrectos</span>
                                </ngb-alert>
                              </ng-container>
                             
                              <ng-container *ngIf="invalidEmployeeRegistered">
                                <ngb-alert type="danger" [dismissible]="true" >
                                  <span class="alert-inner--text">Favor de registrarse en la app móvil</span>
                                </ngb-alert>
                              </ng-container>
                              
                              <ng-container *ngIf="invalidEmployee">
                                <ngb-alert type="danger" [dismissible]="true">
                                  <span class="alert-inner--text">Empleado no válido</span>
                                </ngb-alert>
                              </ng-container>
                              
                            </div>
                          </div>
  

                        <div class="row">
                          <div class="col-12">
                            <h6>No. Empleado</h6>
                            <input class="form-control mb-2" type="text" placeholder="Aa..."  formControlName="user" placeholder="Ingresa número de empleado"> 
                            <div *ngIf="compoIsValid('user')" class="tx-danger">
                                <small>Por favor, Ingresa tú No.Empleado</small>
                            </div> 
                          </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-12">
                              <h6>NIP</h6> 
                              <input class="form-control mb-2" type="password" placeholder="****"  formControlName="nip"  placeholder="Ingresa NIP">
                              <div *ngIf="compoIsValid('nip')" class="tx-danger">
                                <small>Por favor, Ingresa tú Nip</small>
                              </div>
                            </div>
                          </div>
 
                        <div class="row mt-3">
                          <div class="col-sm-12">
                              <button class="btn ripple btn-primary btn-block" type="submit" [disabled]="this.loginForm.invalid" (click)="Login()">Iniciar Sesión</button>
                          </div>
                        </div>
                      
                    </div>

                </form>
                  
                
            </div>
    
        </div>
    </ng-container>
 
</div>
 

<img src="../../../assets/img/brand/logoFeedbakWhite.svg" alt="" class="css_logo">