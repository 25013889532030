import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpService } from '../shared/services/http.service';
import { ILogin } from '../interfaces/iterfaces';
import { CryptoSharkService } from '../shared/services/crypto-shark.service';
import { UserService } from '../shared/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-authentication-exam',
  templateUrl: './authentication-exam.component.html',
  styleUrls: ['./authentication-exam.component.scss']
})
export class AuthenticationExamComponent implements OnInit {
  public login : boolean = true;
  public loginForm: FormGroup;

 
  public submitted: boolean = false;
  public loginData: ILogin = {};
  public returnUrl: string
  public expiredSesionAlert: boolean;
  public invalidCredentials: boolean;
  public invalidEmployee: boolean;
  public invalidEmployeeRegistered: boolean;
  public numUser: number;
  public isUserValid: boolean; 


  public get = (url: string, contentType?: string): Observable<any> => this.http.get(`${environment.urlAdmin}${url}`, contentType ? { headers: { "Content-Type": contentType } } : {});
  @ViewChild('code') codeKey!: ElementRef;

  constructor(private fb: FormBuilder,
              private http: HttpClient,
              private route: ActivatedRoute,
              private httpService: HttpService,
              private cryptoService: CryptoSharkService,
              private userService: UserService,
              private router: Router) { }
 
  ngOnInit(): void { 
    this.formExam();
    this.getSubdomain();
    localStorage.removeItem('ldt');

    this.expiredSesionAlert = this.route.snapshot.queryParams['returnUrl'] ? true : false 
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/certifications'
  }

  get loginFormControl() {
    return this.loginForm.controls;
  }

  /**
   * Obtener el dominio del cliente
   * @returns Dominio del cliente
   */
  getSubdomain() { 
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' ||
      domain.split('.')[0] === 'lvh' ||
      domain.split('.')[0] === 'www' ||
      domain.split('.')[0] === '45' ||
      domain.split('.')[0] === '10'    ) {
      environment.subdomain = 'local'; //pruebas //local
    } else {
      environment.subdomain = domain.split('.')[0];
      environment.subdomain = environment.subdomain.split('-')[0];
    }
  } 
 


  compoIsValid( campo: string ){
    return this.loginForm.controls[ campo ].errors
      && this.loginForm.controls[ campo ].touched
  }
 

  formExam(){
    this.loginForm = this.fb.group({
      user: ['', Validators.required],
      nip: ['', Validators.required], 
    });
     
  }



 async Login() {

    this.get(`api/login/company/${environment.subdomain}`).subscribe(async (response) => {
      this.loginData.company = response.data;
      environment.apiUrl = this.loginData.company.server;
      
      if(this.loginForm.invalid){ return;}

      try {
        this.isUserValid = await this.userService.searchUser({ 
          user: this.cryptoService.encryptAes(
            this.loginForm.controls['user'].value,
            this.loginData.company.key
          ),
          key: this.loginData.company.key,
          data: this.loginData.company.data
        })

        if(!this.isUserValid){
          this.invalidEmployeeRegistered = true;
          setTimeout(()=>{ 
            this.invalidEmployeeRegistered = false;
          },5000) 
          return
        } 
        
      } catch (error) {
        this.invalidEmployee = true;
        setTimeout(()=>{ 
          this.invalidEmployee = false;
        },5000) 
        this.invalidCredentials = false;
        return
      }
      

      

      this.httpService.post(`api/login`, {
        data: this.loginData.company.data,
        key: this.loginData.company.key,
        user: this.cryptoService.encryptAes(
          this.loginForm.controls['user'].value,
          this.loginData.company.key
        ),
        nip: this.cryptoService.encryptSha(
          this.loginForm.controls['nip'].value
        ),
         
      }).toPromise()
      .then((response: any) => { 
        this.loginData.token = response.token;
        this.userService.signInCurrentUser(this.loginData);
        this.router.navigateByUrl(this.returnUrl)  
     
      
      }).catch((e) => { 
        this.invalidCredentials = e.error.message ? true : false; 
        setTimeout(()=>{ 
          this.invalidCredentials = false;
        },5000)
        //console.log(e.error.message);
      })
          
    });

  }

  // startExam(){
  //   localStorage.setItem("code", this.codeKey.nativeElement.value);

  // }

  
}
